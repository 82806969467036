import React, { useEffect, useState, useMemo } from 'react'
import { Router } from '@reach/router' // included with gatsby
import { navigate } from 'gatsby'

import { Loading, ProductSummary, Seo } from '../../components'
import RouterComponent from '../../components/RouterWrapper'

import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useStore } from '../../context/StoreContext'
import { Magento } from '../../services/magento'

const Product = ({ url_key, productData }) => {
  const [product, setProduct] = useState(productData)

  const { storeData, isLoadingStore } = useStore()

  const allProducts = useMemo(
    () => storeData['all-products']?.products ?? [],
    [storeData]
  )

  useEffect(() => {
    if (!isLoadingStore) {
      const productDataFind = allProducts.find(item => item.url_key === url_key)
      if (!productDataFind) {
        navigate('/')
      }
      setProduct(productDataFind)
    }
  }, [allProducts, url_key])

  return (
    <>
      <Seo title={product?.meta_title ?? ''} />
      <ProductSummary {...product} />
    </>
  )
}

const ProductPage = props => {
  const [productData, setProductData] = useState(null)
  const {
    prismicData: { prismicProductDetailPage },
  } = usePrismic()

  const { loading_product } = prismicProductDetailPage
  const { isEventSite, loading } = useAuthContext()
  const { isLoadingStore } = useStore()

  useEffect(() => {
    if (!isLoadingStore) return
    if (loading) return
    ;(async () =>
      await Magento.Store.getSingleProductByUrlKey({
        url_key: props.params['*'],
      }).then(({ products }) => {
        setProductData(products.items[0])
      }))()
  }, [loading])

  if (isLoadingStore && !productData)
    return (
      <Loading
        loading={true}
        message={loading_product[0].text}
        showBg={isEventSite}
      />
    )

  return (
    <>
      <Router primary={false} component={RouterComponent}>
        <Product path="/products/:url_key" productData={productData} />
      </Router>
    </>
  )
}

export default ProductPage
